<template>
  <div :id="componentId" class="p-4">
    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon name="riGlobalLine" size="20" class="inline-block mr-1" />
        <span>{{ block.details.name }}</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
    </div>
    <tag-selector :block="block" :editor="editor" />
    <textarea
      :value="block.data.text"
      rows="7"
      cols="35"
      class="px-4 py-2 mb-1 rounded-lg block bg-input"
      placeholder="Nhập câu nói..."
      type="text"
      required
      @input="handleInput"
    />
    <tag-content-preview :block="block" />

    <n-alert
      v-if="state.emptyData"
      title="Mẹo"
      type="warning"
      closable
      style="margin: 6px 0"
    >
      Đừng quên nhập nội dung bạn nhé!
    </n-alert>
    <!-- <ui-checkbox :model-value="block.data.active" @change="handleCheckbox">
      Set as active tab
    </ui-checkbox> -->
  </div>
</template>
<script setup>
import emitter from "tiny-emitter/instance";
import TagSelector from "./TagSelector";
import { NAlert } from "naive-ui";
import { debounce } from "@/utils/helper";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";
import { getCurrentInstance, reactive, ref, watch } from "vue";
import TagContentPreview from "./TagContentPreview";
const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});
const state = reactive({
  emptyData: true,
});

const componentId = useComponentId("speaking");
const block = useEditorBlock(`#${componentId}`, props.editor);

watch(block, () => {
  state.emptyData = !block.data.text || block.data.text === "";
});

const handleInput = debounce(({ target }) => {
  state.emptyData = !target.value || target.value === "";

  const value = target.value;

  props.editor.updateNodeDataFromId(block.id, { ...block.data, text: value });
  block.data.text = value;
  emitter.emit("editor:data-changed", block.id);
}, 250);

function handleCheckbox(value) {
  props.editor.updateNodeDataFromId(block.id, { ...block.data, active: value });
  block.data.active = value;
  emitter.emit("editor:data-changed", block.id);
}
</script>
