<template>
  <div :id="componentId" class="p-4 repeat">
    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon name="riRepeat2Line" size="20" class="inline-block mr-1" />
        <span>Bot Nói Lại</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
    </div>
    <!-- <ui-textarea
      v-model="block.data.text"
      placeholder="Nhập câu nói..."
      class="mb-2 w-full"
      required
    /> -->
    <tag-selector :block="block" :editor="editor" />
    <textarea
      :value="block.data.text"
      rows="7"
      cols="35"
      class="px-4 py-2 mb-1 rounded-lg block bg-input"
      placeholder="Nhập câu nói..."
      type="text"
      required
      @input="handleInputText"
    />
    <label
      class="
        mb-2
        block
        bg-input
        focus-within:bg-input
        pr-4
        transition
        rounded-lg
      "
    >
      <input
        :value="block.data.repeat_number || 1"
        min="1"
        class="pl-4 py-2 bg-transparent rounded-l-lg w-24 mr-2"
        type="number"
        required
        @input="handleInput"
      />
      <span class="text-gray-600">Lần</span>
    </label>
    <p class="text-right text-gray-600">Lặp lại nhiều hơn</p>
    <tag-content-preview :block="block" />
    <n-alert
      v-if="state.emptyData"
      title="Mẹo"
      type="warning"
      closable
      style="margin: 6px 0"
    >
      Đừng quên nhập nội dung bạn nhé!
    </n-alert>
  </div>
</template>
<script setup>
import emitter from "tiny-emitter/instance";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";
import TagContentPreview from "./TagContentPreview";
import TagSelector from "./TagSelector";
import { NAlert } from "naive-ui";
import { reactive, watch } from "vue";

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});
const state = reactive({
  emptyData: false,
});

const componentId = useComponentId("block-delay");
const block = useEditorBlock(`#${componentId}`, props.editor);

watch(block, () => {
  state.emptyData = !block.data.text || block.data.text === "";
});
function handleInputText({ target }) {
  state.emptyData = !target.value || target.value === "";

  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, { ...block.data, text: value });
  block.data.text = value;
  emitter.emit("editor:data-changed", block.id);
}

function handleInput({ target }) {
  target.reportValidity();
  const value = +target.value || 0;
  if (value < 0) return;

  // props.editor.updateNodeDataFromId(block.id, { repeatFor });
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    repeat_number: value,
  });
  block.data.repeat_number = value;
  emitter.emit("editor:data-changed", block.id);
}
</script>
<style>
.drawflow .repeat .outputs {
  /* top: 74px !important; */
  /* top: 154px !important; */
  /* top: 84px !important; */
  top: 263px !important;
  transform: none !important;
}
.drawflow .repeat .output {
  margin-bottom: 22px;
}
</style>
