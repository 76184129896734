<template>
  <div :id="componentId" class="p-4">

    <n-alert
      v-if="state.emptyCondition"
      title="Mẹo 1"
      type="warning"
      closable
      style="margin: 6px 0"
    >
      Bấm dấu cộng để thêm điều kiện logic cho bot nghe
    </n-alert>
    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon name="riAB" size="20" class="inline-block mr-1" />
        <span>Bot Nghe</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer mr-2"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
      <!-- <ui-button
        :disabled="block.data.conditions && block.data.conditions.length > 9"
        icon
        variant="accent"
        style="height: 37px; width: 37px"
        @click="addComparison"
      > -->
      <ui-button
        icon
        variant="accent"
        style="height: 37px; width: 37px"
        @click="addComparison"
      >
        <v-remixicon name="riAddLine" class="inline-block" />
      </ui-button>
    </div>
    <input
      :value="block.data.title"
      class="px-4 py-2 mb-1 rounded-lg block bg-input mb-2"
      placeholder="Nhập tag name"
      type="text"
      required
      style="width: 100%"
      @input="handleInputTitle"
    />
    <label
      class="
        mb-2
        block
        bg-input
        focus-within:bg-input
        pr-4
        transition
        rounded-lg
      "
    >
      <input
        :value="block.data.listen_time || 3"
        min="2"
        class="pl-4 py-2 bg-transparent rounded-l-lg w-24 mr-2"
        type="number"
        required
        @input="handleInput"
      />
      <span class="text-gray-600">Giây</span>
    </label>
    <!-- <div
      v-if="block.data.conditions && block.data.conditions.length !== 0"
      class="mt-4 space-y-2"
    > -->
    <div v-if="block.data.conditions" class="mt-4 space-y-2">
      <div
        v-for="(item, index) in block.data.conditions"
        :key="item.id"
        class="flex items-center group justify-end"
      >
        <v-remixicon
          name="riDeleteBin7Line"
          class="mr-2 invisible group-hover:visible cursor-pointer"
          @click="deleteComparison(index)"
        />
        <div class="flex items-center transition bg-input rounded-lg">
          <select
            v-model="block.data.conditions[index].type"
            :title="
              conditions[block.data.conditions[index]?.type] || 'Contains'
            "
            class="
              bg-transparent
              font-mono
              z-10
              p-2
              text-center
              transition
              rounded-l-lg
              appearance-none
            "
          >
            <option
              v-for="(name, type) in conditions"
              :key="type"
              :value="type"
            >
              {{ type }}
            </option>
          </select>
          <div class="bg-gray-300 w-px" style="height: 30px"></div>
          <input
            v-if="block.data.conditions[index].type === 'Từ khoá'"
            v-model="block.data.conditions[index].value"
            type="text"
            placeholder="Nhập từ khoá..."
            class="p-2 flex-1 transition rounded-r-lg bg-transparent w-36"
          />
          <input
            v-if="block.data.conditions[index].type === 'Im lặng'"
            v-model="block.data.conditions[index].value"
            type="text"
            class="p-2 flex-1 transition rounded-r-lg bg-transparent w-36"
            disabled="true"
          />
        </div>
      </div>
      <!-- <p
        v-if="block.data.conditions && block.data.conditions.length !== 0"
        class="text-right text-gray-600"
      > -->
      <p v-if="block.data.conditions" class="text-right text-gray-600">
        <span title="Execute when all comparisons don't meet the requirement">
          &#9432;
        </span>
        Mặc định
      </p>
    </div>

    <n-alert
      v-if="state.emptyData"
      title="Mẹo 2"
      type="warning"
      closable
      style="margin: 6px 0"
    >
      Tag name giúp bạn xác định tên cột khi xuất báo cáo
    </n-alert>
  </div>
</template>
<script setup>
import { watch, toRaw, reactive } from "vue";
import emitter from "tiny-emitter/instance";
import { debounce } from "@/utils/helper";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";
import { NAlert } from "naive-ui";
import { useToast } from "vue-toastification";
const toast = useToast();

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});
const state = reactive({
  emptyData: true,
  emptyCondition: true,
});

const componentId = useComponentId("block-listening");
const block = useEditorBlock(`#${componentId}`, props.editor);

watch(block, () => {
  state.emptyData = !block.data.title || block.data.title === "";
  state.emptyCondition =
    !block.data.conditions || block.data.conditions.length === 0;
  if (
    block.data.conditions &&
    Object.entries(block.data.conditions).filter((t) => {
      return t[1].type === "Im lặng";
    }).length >= 2
  ) {
    toast.warning("Bạn đang có 2 điều kiện im lặng", {
      timeout: 2000,
    });
  }
});

function handleInput({ target }) {
  target.reportValidity();
  const value = +target.value || 0;
  if (value < 0) return;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    listen_time: value,
  });
  block.data.listen_time = value;
  emitter.emit("editor:data-changed", block.id);
}

function handleInputTitle({ target }) {
  target.reportValidity();
  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    title: value,
  });
  block.data.title = value;
  emitter.emit("editor:data-changed", block.id);
}

const conditions = {
  "Im lặng": "Silent",
  "Từ khoá": "Contains",
};

function addComparison() {
  // if (block.data.conditions.length >= 10) return;

  block.data.conditions.push({ type: "Từ khoá", value: "" });

  // if (block.data.conditions.length === 1) props.editor.addNodeOutput(block.id);

  props.editor.addNodeOutput(block.id);
}
function deleteComparison(index) {
  block.data.conditions.splice(index, 1);

  props.editor.removeNodeOutput(block.id, `output_${index + 1}`);
  // if (block.data.conditions.length === 0)
  //   props.editor.removeNodeOutput(block.id, `output_1`);
}

watch(
  () => block.data.conditions,
  debounce((newValue, oldValue) => {
    props.editor.updateNodeDataFromId(block.id, {
      ...block.data,
      conditions: toRaw(newValue),
    });
    block.data.conditions = toRaw(newValue);

    props.editor.updateConnectionNodes(`node-${block.id}`);

    if (oldValue) {
      emitter.emit("editor:data-changed", block.id);
    }
  }, 250),
  { deep: true }
);
</script>
<style>
.drawflow .drawflow-node.listening .outputs {
  /* top: 82px !important; */
  top: 175px !important;
  transform: none !important;
}
.drawflow .drawflow-node.listening .output {
  margin-bottom: 28px;
}
.drawflow .drawflow-node.listening .output:nth-last-child(2) {
  margin-bottom: 22px;
}
</style>
