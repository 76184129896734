<template>
  <div :id="componentId" class="p-4">
    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon
          name="riCloseCircleLine"
          size="20"
          class="inline-block mr-1"
        />
        <span>{{ block.details.name }}</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
    </div>
    <textarea
      rows="4"
      cols="35"
      :value="block.data.text"
      class="px-4 py-2 mb-1 rounded-lg block bg-input"
      placeholder="Nhập câu nói..."
      type="text"
      required
      @input="handleInput"
    />
  </div>
</template>
<script setup>
import emitter from "tiny-emitter/instance";
import { debounce } from "@/utils/helper";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});

const componentId = useComponentId("new-tab");
const block = useEditorBlock(`#${componentId}`, props.editor);

const handleInput = debounce(({ target }) => {
  target.reportValidity();

  const res = target.value.match(
    /* eslint-disable-next-line */
    // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    /.+/g
  );

  if (!res) return;

  const [value] = res;

  props.editor.updateNodeDataFromId(block.id, { ...block.data, text: value });
  block.data.text = value;
  emitter.emit("editor:data-changed", block.id);
}, 250);
</script>
