<template>
  <block-base
    :id="componentId"
    :hide-edit="block.details.disableEdit"
    :hide-delete="block.details.disableDelete"
    content-class="flex items-center"
    @edit="editBlock"
    @delete="editor.removeNodeId(`node-${block.id}`)"
  >
    <span
      :class="block.category.color"
      class="inline-block p-2 mr-2 rounded-lg bg-green-200"
    >
      <v-remixicon :name="block.details.icon || 'riGlobalLine'" />
    </span>
    <div style="">
      <p class="font-semibold leading-none whitespace-nowrap">
        Gắn nhãn khách hàng
      </p>
      <p class="text-gray-600 text-overflow leading-tight">
        {{ block.data.description }}
      </p>
      <input
        :value="block.data.text"
        name="text"
        type="text"
        class="px-4 py-2 mt-2 mb-1 rounded-lg block bg-input"
        placeholder="khach_quan_trong"

        @input="handleInput"
      />
    </div>
  </block-base>
</template>
<script setup>
import emitter from "tiny-emitter/instance";
import { useEditorBlock } from "@/composable/editorBlock";
import { useComponentId } from "@/composable/componentId";
import BlockBase from "./BlockBase.vue";
import { debounce } from "@/utils/helper";

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});

const componentId = useComponentId("block-lead-customer");
const block = useEditorBlock(`#${componentId}`, props.editor);

function editBlock() {
  emitter.emit("editor:edit-block", {
    ...block.details,
    data: block.data,
    blockId: block.id,
  });
}

const handleInput = debounce(({ target }) => {
  const value = target.value;

  props.editor.updateNodeDataFromId(block.id, { ...block.data, text: value });
  block.data.text = value;
  emitter.emit("editor:data-changed", block.id);
}, 250);

</script>
