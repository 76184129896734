<template>
  <div :id="componentId" class="p-4">
    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon name="riSpeakerFill" size="20" class="inline-block mr-1" />
        <span>{{ block.details.name }}</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
    </div>

    <div v-if="!audioUrl">
      <h3 style="margin-bottom: 6px">
        <b>Chọn file audio (định dạng: mp3, wav)</b>
      </h3>
      <input type="file" @change="onFileChange" v-if="!audioUploading" />
      <div style="padding-bottom: 16px" v-else>
        <ui-spinner />
      </div>
      <h3 style="margin-top: 12px">
        <b style="margin-bottom: 16px">hoặc tự ghi âm</b>
        <div class="width: 200px; height: 300px">

          <AudioRecorder
            :timer="true"
            timer-color="#333"
            :timer-font-size="1.2"
            timer-background="#eee"
            :audio-width="200"
            :removeAudioCallback="removeAudio"
            @audioFile="myAudioFile"
          />
        </div>
      </h3>
    </div>
    <div v-else>
      <audio controls>
        <source v-bind:src="audioUrl" style="margin-bottom: 8px" />
        Your browser does not support the audio element.
      </audio>
      <ui-button
        @click.prevent="removeAudio"
        style="margin-top: 8px; background: #e76666; color: white"
        >Chọn audio khác</ui-button
      >
    </div>
    <n-alert
      v-if="state.emptyData"
      title="Mẹo"
      type="warning"
      closable
      style="margin: 6px 0"
    >
      Đừng quên chọn audio bạn nhé!
    </n-alert>
  </div>
</template>
<script setup>
import emitter from "tiny-emitter/instance";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";
import { uploadAudioFile } from "../../api";
import { reactive, ref, watch } from "vue";
import UiButton from "../ui/UiButton";
import UiSpinner from "../ui/UiSpinner";
import { NAlert } from "naive-ui";
import AudioRecorder from './AudioRecorder'
const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  },
});
const componentId = useComponentId("audio");
const block = useEditorBlock(`#${componentId}`, props.editor);

const audioUrl = ref(undefined);
const audioUploading = ref(false);

const state = reactive({
  emptyData: false,
});

watch(block, () => {
  const value = block.data.url;
  state.emptyData = !value || value === "";
  audioUrl.value = value;
});


async function onFileChange(e) {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return false;

  audioUploading.value = true;
  audioUrl.value = await uploadAudioFile(files[0]);
  audioUploading.value = false;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    url: audioUrl.value,
  });
  block.data.url = audioUrl.value;
  emitter.emit("editor:data-changed", block.id);
}

const removeAudio = () => {
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    url: undefined,
  });
  audioUrl.value = undefined;
  block.data.url = undefined;
  emitter.emit("editor:data-changed", block.id);
};


async function myAudioFile(audioFile) {
  console.log('myAudioFile: ', audioFile);
  const file = new File([audioFile], "audiofile.wav")
  audioUploading.value = true;
  audioUrl.value = await uploadAudioFile(file);
  audioUploading.value = false;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    url: audioUrl.value,
  });
  block.data.url = audioUrl.value;
  emitter.emit("editor:data-changed", block.id);
}
</script>
