import axios from "axios";

const isDev =
  window.location.href.includes(".dev") ||
  window.location.href.includes("localhost");

const isV2 = window.location.hostname?.includes("v2.bot");
const endpointV2 = isDev
  ? "https://api-v2.ucall.vn"
  : "https://api-v2.ucall.vn";

const endpointV1 = isDev
  ? "https://api.dev.ucall.asia"
  : "https://api.ucall.asia";

const endpoint = isV2 ? endpointV2 : endpointV1;

// const uploadEndpoint = isDev
//   ? "https://tts.dev.ucall.asia/v1/api/record"
//   : "https://tts.ucall.asia/v1/api/record";
const uploadEndpoint = "https://tts-arm.ucall.vn/v1/api/record";

const getBotDetail = async () => {
  try {
    if (isV2) {
      const response = await axios.get(
        `${endpoint}/api/company/${sessionStorage.getItem(
          "company"
        )}/call_bot_scenario/${sessionStorage.getItem("slugId")}`,
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    } else {
      const response = await axios.get(
        `${endpoint}/api/campaigns/${sessionStorage.getItem(
          "campaignId"
        )}/bots/${sessionStorage.getItem("slugId")}/`,
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

const getCampaignDetail = async () => {
  try {
    if (isV2) {
      const response = await axios.get(
        `${endpoint}/api/company/${sessionStorage.getItem(
          "company"
        )}/call_bot_scenario/${sessionStorage.getItem("slugId")}/`,
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    } else {
      const response = await axios.get(
        `${endpoint}/api/campaigns/${sessionStorage.getItem("campaignId")}/`,
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

const updateBotDetail = async (data) => {
  try {
    if (isV2) {
      const response = await axios.put(
        `${endpoint}/api/company/${sessionStorage.getItem(
          "company"
        )}/call_bot_scenario/${sessionStorage.getItem("slugId")}`,

        {
          content: {
            dataColumns: {},
            icon: "riGlobalLine",
            name: "CustomFlow",
            settings: {},
            drawflow: JSON.stringify(data),
          },
        },
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    } else {
      const response = await axios.put(
        `${endpoint}/api/campaigns/${sessionStorage.getItem(
          "campaignId"
        )}/bots/${sessionStorage.getItem("slugId")}/`,

        {
          content: {
            dataColumns: {},
            icon: "riGlobalLine",
            name: "CustomFlow",
            settings: {},
            drawflow: JSON.stringify(data),
          },
        },
        {
          headers: {
            "x-api-key": sessionStorage.getItem("x-api-key"),
          },
        }
      );
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

const uploadAudioFile = async (data) => {
  try {
    let formData = new FormData();
    formData.append("audio", data);
    const response = await axios.post(uploadEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": sessionStorage.getItem("x-api-key"),
      },
    });
    return response.data?.url;
  } catch (e) {
    console.log(e);
  }
};

export { getBotDetail, updateBotDetail, uploadAudioFile, getCampaignDetail };
