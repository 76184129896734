<template>
  <small class="px-4">uCall Bot Editor v{{ appVersion }}</small>
  <div class="px-4 flex items-center mb-2">
    <p
      class="
        font-semibold
        text-overflow
        inline-block
        text-lg
        flex-1
        mr-4
        align-middle
      "
    >
      {{ botName }}
    </p>
  </div>
  <div class="flex px-4 mt-2 space-x-2">
    <ui-button variant="accent" class="flex-1 relative" @click="$emit('save')">
      <span
        v-if="dataChanged"
        class="flex h-3 w-3 absolute top-0 left-0 -ml-1 -mt-1"
      >
        <span
          class="
            animate-ping
            absolute
            inline-flex
            h-full
            w-full
            rounded-full
            bg-primary
            opacity-75
          "
        ></span>
        <span
          class="relative inline-flex rounded-full h-3 w-3 bg-blue-600"
        ></span>
      </span>
      <v-remixicon name="riSaveLine" class="mr-2 -ml-1" />
      Lưu
    </ui-button>
    <!--    <ui-button icon title="Execute" @click="$emit('execute')">-->
    <!--      <v-remixicon name="riPlayLine" />-->
    <!--    </ui-button>-->
    <!--    <ui-popover>-->
    <!--      <template #trigger>-->
    <!--        <ui-button icon title="More">-->
    <!--          <v-remixicon name="riMore2Line" />-->
    <!--        </ui-button>-->
    <!--      </template>-->
    <!--&lt;!&ndash;      <ui-list>&ndash;&gt;-->
    <!--&lt;!&ndash;        <ui-list-item&ndash;&gt;-->
    <!--&lt;!&ndash;          v-close-popover&ndash;&gt;-->
    <!--&lt;!&ndash;          class="cursor-pointer"&ndash;&gt;-->
    <!--&lt;!&ndash;          @click="$emit('rename')"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <v-remixicon name="riPencilLine" class="mr-2 -ml-1" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>Rename</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </ui-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;        <ui-list-item&ndash;&gt;-->
    <!--&lt;!&ndash;          v-close-popover&ndash;&gt;-->
    <!--&lt;!&ndash;          class="cursor-pointer"&ndash;&gt;-->
    <!--&lt;!&ndash;          @click="$emit('export', workflow)"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <v-remixicon name="riDownloadLine" class="mr-2 -ml-1" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>Export</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </ui-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;        <ui-list-item&ndash;&gt;-->
    <!--&lt;!&ndash;          v-close-popover&ndash;&gt;-->
    <!--&lt;!&ndash;          class="cursor-pointer"&ndash;&gt;-->
    <!--&lt;!&ndash;          @click="$emit('showDataColumns')"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <v-remixicon name="riKey2Line" class="mr-2 -ml-1" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>Data columns</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </ui-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;        <ui-list-item&ndash;&gt;-->
    <!--&lt;!&ndash;          v-close-popover&ndash;&gt;-->
    <!--&lt;!&ndash;          class="cursor-pointer"&ndash;&gt;-->
    <!--&lt;!&ndash;          @click="$emit('showSettings')"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <v-remixicon name="riSettings3Line" class="mr-2 -ml-1" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>Settings</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </ui-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;        <ui-list-item&ndash;&gt;-->
    <!--&lt;!&ndash;          v-close-popover&ndash;&gt;-->
    <!--&lt;!&ndash;          class="cursor-pointer"&ndash;&gt;-->
    <!--&lt;!&ndash;          @click="$emit('delete')"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <v-remixicon name="riDeleteBin7Line" class="mr-2 -ml-1" />&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>Delete</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </ui-list-item>&ndash;&gt;-->
    <!--&lt;!&ndash;      </ui-list>&ndash;&gt;-->
    <!--    </ui-popover>-->
  </div>
  <hr class="m-4 border-gray-100" />
  <div class="scroll bg-scroll overflow-auto px-4 flex-1 overflow-auto">
    <template v-for="(items, catId) in taskList" :key="catId">
      <div class="flex items-center top-0 space-x-2 mb-2">
        <span
          :class="categories[catId].color"
          class="h-3 w-3 rounded-full"
        ></span>
        <p class="capitalize text-gray-600">{{ categories[catId].name }}</p>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-4">
        <div
          v-for="block in items"
          :key="block.id"
          :title="block.description || block.name"
          draggable="true"
          class="
            select-none
            cursor-move
            relative
            p-4
            rounded-lg
            bg-input
            transition
          "
          @dragstart="
            $event.dataTransfer.setData('block', JSON.stringify(block))
          "
        >
          <v-remixicon :name="block.icon" size="24" class="mb-2" />
          <p class="leading-tight" style="white-space: pre-wrap">
            {{ block.name }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { tasks, categories } from "@/utils/shared";
import { version } from "../../../../package.json";

const appVersion = version;
console.log(appVersion);
defineProps({
  workflow: {
    type: Object,
    default: () => ({}),
  },
  dataChanged: {
    type: Boolean,
    default: false,
  },
  botName: {
    type: String,
    default: () => window.sessionStorage.getItem("botName"),
  },
});
defineEmits([
  "save",
  "export",
  "update",
  "rename",
  "delete",
  "execute",
  "showSettings",
  "showDataColumns",
]);

const taskList = Object.keys(tasks).reduce((arr, key) => {
  const task = tasks[key];

  (arr[task.category] = arr[task.category] || []).push({ id: key, ...task });

  return arr;
}, {});
const icons = [
  "riGlobalLine",
  "riFileTextLine",
  "riEqualizerLine",
  "riTimerLine",
  "riCalendarLine",
  "riFlashlightLine",
  "riLightbulbFlashLine",
  "riDatabase2Line",
  "riWindowLine",
  "riNotification2Line",
  "riCursorLine",
  "riDownloadLine",
  "riCommandLine",
];
</script>
