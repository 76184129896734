import { createStore } from "vuex";
import vuexORM from "@/lib/vuex-orm";
import * as models from "@/models";

const store = createStore({
  plugins: [vuexORM(models)],
  state: () => ({
    workflowState: [],
  }),
  mutations: {
    updateState(state, { key, value }) {
      state[key] = value;
    },
  },
  getters: {
    getWorkflowState: (state) => (id) =>
      (state.workflowState || []).filter(({ workflowId }) => workflowId === id),
  },
  actions: {
    async retrieve({ dispatch, getters }, keys = "workflows") {},
    async retrieveWorkflowState({ commit }) {},
    saveToStorage({ getters }, key) {
      return new Promise((resolve, reject) => {});
    },
  },
});

export default store;
