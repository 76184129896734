<template>
  <div :id="componentId" class="p-4">

    <div class="flex items-center mb-2">
      <div
        :class="block.category.color"
        class="inline-block text-sm mr-4 p-2 rounded-lg"
      >
        <v-remixicon name="riWifiFill" size="20" class="inline-block mr-1" />
        <span>Block Gọi API</span>
      </div>
      <div class="flex-grow"></div>
      <v-remixicon
        name="riDeleteBin7Line"
        class="cursor-pointer mr-2"
        @click="editor.removeNodeId(`node-${block.id}`)"
      />
    </div>
    <input
      :value="block.data.url"
      class="px-4 py-2 mb-1 rounded-lg block bg-input mb-2"
      placeholder="Nhập URL"
      type="text"
      required
      style="width: 100%"
      @input="handleInputUrl"
    />
    <input
      :value="block.data.url"
      class="px-4 py-2 mb-1 rounded-lg block bg-input mb-2"
      placeholder="Nhập Method (GET/POST)"
      type="text"
      required
      style="width: 100%"
      @input="handleInputMethod"
    />
    <input
      :value="block.data.authorization"
      class="px-4 py-2 mb-1 rounded-lg block bg-input mb-2"
      placeholder="Authorization (nếu có)"
      type="text"
      required
      style="width: 100%"
      @input="handleAuthorization"
    />
    <input
      :value="block.data.data"
      class="px-4 py-2 mb-1 rounded-lg block bg-input mb-2"
      placeholder="Data (nếu có)"
      type="text"
      required
      style="width: 100%"
      @input="handleData"
    />
  </div>
</template>
<script setup>
import { reactive } from "vue";
import emitter from "tiny-emitter/instance";
import { useComponentId } from "@/composable/componentId";
import { useEditorBlock } from "@/composable/editorBlock";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  editor: {
    default: () => ({}),
  },
});
const state = reactive({
  emptyData: true,
  emptyCondition: true,
});

const componentId = useComponentId("block-call-api");
const block = useEditorBlock(`#${componentId}`, props.editor);


function handleInputUrl({ target }) {
  target.reportValidity();
  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    url: value,
  });
  block.data.url = value;
  emitter.emit("editor:data-changed", block.id);
}

function handleAuthorization({ target }) {
  target.reportValidity();
  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    authorization: value,
  });
  block.data.authorization = value;
  emitter.emit("editor:data-changed", block.id);
}


function handleData({ target }) {
  target.reportValidity();
  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    data: value,
  });
  block.data.data = value;
  emitter.emit("editor:data-changed", block.id);
}

function handleInputMethod({ target }) {
  target.reportValidity();
  const value = target.value;
  props.editor.updateNodeDataFromId(block.id, {
    ...block.data,
    method: value,
  });
  block.data.method = value;
  emitter.emit("editor:data-changed", block.id);
}

</script>
<style>
.drawflow .drawflow-node.listening .outputs {
  /* top: 82px !important; */
  top: 175px !important;
  transform: none !important;
}
.drawflow .drawflow-node.listening .output {
  margin-bottom: 28px;
}
.drawflow .drawflow-node.listening .output:nth-last-child(2) {
  margin-bottom: 22px;
}
</style>
