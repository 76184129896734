import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import AsyncComputed from "vue-async-computed";
import compsUi from "./lib/comps-ui";
import vRemixicon, { icons } from "./lib/v-remixicon";
import Toast from "vue-toastification";
import "./assets/css/tailwind.css";
import "./assets/css/fonts.css";
import "./assets/css/style.css";
import "vue-toastification/dist/index.css";
import TagSelector from "./components/block/TagSelector";

export const app = createApp(App)
  .use(router)
  .use(store)
  .use(compsUi)
  .use(AsyncComputed)
  .use(vRemixicon, icons)
  .use(Toast, {})
  .mount("#app");

if (module.hot) module.hot.accept();
