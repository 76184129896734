import vRemixicon from "v-remixicon";
import {
  riHome5Line,
  riGithubFill,
  riRecordCircleLine,
  riErrorWarningLine,
  riCalendarLine,
  riFileTextLine,
  riFilter2Line,
  riArrowGoBackLine,
  riArrowGoForwardLine,
  riDatabase2Line,
  riSettings3Line,
  riWindowLine,
  riNotification2Line,
  riKey2Line,
  riRestartLine,
  riTBoxLine,
  riAB,
  riSaveLine,
  riSubtractLine,
  riPlayLine,
  riPauseLine,
  riSearch2Line,
  riMoreLine,
  riDeleteBin7Line,
  riPencilLine,
  riExternalLinkLine,
  riLink,
  riArrowLeftSLine,
  riArrowLeftLine,
  riEditBoxLine,
  riStopLine,
  riCheckboxCircleLine,
  riFlowChart,
  riHistoryLine,
  riArrowDropDownLine,
  riAddLine,
  riFullscreenLine,
  riSortAsc,
  riSortDesc,
  riGlobalLine,
  riMore2Line,
  riInputCursorMove,
  riRepeat2Line,
  riMouseLine,
  riBracketsLine,
  riEqualizerLine,
  riFocusLine,
  riCursorLine,
  riDownloadLine,
  riUploadLine,
  riCommandLine,
  riParagraph,
  riImageLine,
  riCloseLine,
  riCloseCircleLine,
  riDragDropLine,
  riCheckLine,
  riFocus3Line,
  riTimerLine,
  riLightbulbFlashLine,
  riFlashlightLine,
  riFlagLine,
  riSpeakerFill,
  riPriceTag2Fill,
  riWifiFill,
  riPhoneFill,
  riRecordCircleFill,
} from "v-remixicon/icons";

export const icons = {
  riHome5Line,
  riGithubFill,
  riRecordCircleLine,
  riErrorWarningLine,
  riCalendarLine,
  riFileTextLine,
  riFilter2Line,
  riArrowGoBackLine,
  riArrowGoForwardLine,
  riDatabase2Line,
  riSettings3Line,
  riWindowLine,
  riNotification2Line,
  riKey2Line,
  riRestartLine,
  riTBoxLine,
  riAB,
  riSaveLine,
  riSubtractLine,
  riPlayLine,
  riPauseLine,
  riSearch2Line,
  riMoreLine,
  riDeleteBin7Line,
  riPencilLine,
  riExternalLinkLine,
  riLink,
  riArrowLeftSLine,
  riArrowLeftLine,
  riEditBoxLine,
  riStopLine,
  riCheckboxCircleLine,
  riFlowChart,
  riHistoryLine,
  riArrowDropDownLine,
  riAddLine,
  riFullscreenLine,
  riSortAsc,
  riSortDesc,
  riGlobalLine,
  riMore2Line,
  riInputCursorMove,
  riRepeat2Line,
  riMouseLine,
  riBracketsLine,
  riEqualizerLine,
  riFocusLine,
  riCursorLine,
  riDownloadLine,
  riUploadLine,
  riCommandLine,
  riParagraph,
  riImageLine,
  riCloseLine,
  riCloseCircleLine,
  riDragDropLine,
  riCheckLine,
  riFocus3Line,
  riTimerLine,
  riLightbulbFlashLine,
  riFlashlightLine,
  riFlagLine,
  riWifiFill,
  riPriceTag2Fill,
  riPhoneFill,
  riSpeakerFill,
  riRecordCircleFill,
  mdiEqual: "M19,10H5V8H19V10M19,16H5V14H19V16Z",
  mdiDrag:
    "M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z",
};

export default vRemixicon;
