<template>
  <hr style="margin-top: 16px" />
  <n-collapse>
    <n-collapse-item
      title="Xem trước"
      name="1"
      style="margin-bottom: 4px; margin-top: 8px"
    >
      <div
        class="px-4 py-2 mb-1 rounded-lg block bg-input"
        style="
          resize: both;
          overflow: auto;
          width: 360px;
          min-height: 100px;
          white-space: pre-line;
        "
        v-html="contentFormatHtml"
      />
    </n-collapse-item>
  </n-collapse>
</template>

<script setup>
import { NCollapse, NCollapseItem } from "naive-ui";
import { ref, watch } from "vue";
const contentFormatHtml = ref("");
const props = defineProps({
  block: {
    type: Object,
    default: () => ({}),
  },
});
const block = props.block;
watch(block, () => {
  contentFormatHtml.value = replaceRegex(block?.data?.text);
});
const replaceRegex = (text) => {
  const matches = text.match(/\$(?:[^\$\\]|\\.)*\$/g);

  if (!matches || matches.length === 0) {
    return text;
  }
  matches.forEach(function (match) {
    text = text.replace(
      match,
      `<button class="n-button n-button--default-type n-button--medium-type n-button--color" tabindex="0" type="button" style="--bezier:cubic-bezier(0.4, 0, 0.2, 1); --bezier-ease-out:cubic-bezier(0, 0, 0.2, 1); --ripple-duration:0.6s; --opacity-disabled:0.5; --wave-opacity:0.6; font-weight: 400; --color:rgb(44, 57, 160); --color-hover:rgba(78, 89, 175, 1); --color-pressed:rgba(39, 50, 141, 1); --color-focus:rgba(78, 89, 175, 1); --color-disabled:rgb(44, 57, 160); --ripple-color:rgb(44, 57, 160); --text-color:#FFF; --text-color-hover:#FFF; --text-color-pressed:#FFF; --text-color-focus:#FFF; --text-color-disabled:#FFF; --border:1px solid rgb(224, 224, 230); --border-hover:1px solid #36ad6a; --border-pressed:1px solid #0c7a43; --border-focus:1px solid #36ad6a; --border-disabled:1px solid rgb(224, 224, 230); --width: initial; --height:34px; --font-size:14px; --padding:0 14px; --icon-size:18px; --icon-margin:6px; --border-radius:3px; margin: 4px;"><!----><!----><span class="n-button__content"><span style="font-weight: 600; margin-right: 8px;">${match
        .toString()
        .replaceAll(
          "$",
          ""
        )}</span></span><div aria-hidden="true" class="n-base-wave"></div><!----><!----></button>`
    );
  });
  return text;
};
</script>
