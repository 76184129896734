<template>
  <div>
    <div v-if="loading" class="content-center">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div v-if="error" class="content-center">Có lỗi xảy ra</div>

    <div v-if="workflow" class="content">
      <workflow-container />
    </div>
  </div>
</template>

<script>
import { getBotDetail } from "../../api";
import WorkflowContainer from "../../components/newtab/workflow/WorkflowContainer";

export default {
  components: { WorkflowContainer },
  data() {
    return {
      loading: false,
      workflow: null,
      error: null,
    };
  },
  created() {
    this.getParams();
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    getParams() {
      const workflowId = this.$route.params.slugId;
      console.log(this.$route.query)
      if (this.$route.query.api_key) {
        window.sessionStorage.setItem("x-api-key", this.$route.query.api_key);
      }
      if (this.$route.query['x-api-key']) {
        window.sessionStorage.setItem("x-api-key", this.$route.query['x-api-key']);
      }
      window.sessionStorage.setItem("slugId", workflowId);
      if (this.$route.query.campaign) {
        window.sessionStorage.setItem("campaignId", this.$route.query.campaign);
      }
      if (this.$route.query.campaignId) {
        window.sessionStorage.setItem("campaignId", this.$route.query.campaignId);
      }
      if (this.$route.query.company) {
        window.sessionStorage.setItem("company", this.$route.query.company);
      }
    },
    fetchData() {
      this.error = this.workflow = null;
      this.loading = true;
      const slugId = this.$route.params.slugId;
      // replace `getPost` with your data fetching util / API wrapper
      getBotDetail(slugId).then((response) => {
        this.loading = false;
        this.workflow = response.data;
        window.sessionStorage.setItem(
          "workflow",
          JSON.stringify(response.data)
        );

        window.sessionStorage.setItem("botName", response.data.title);
      });
    },
  },
};
</script>

<style>
.content-center {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(44, 57, 160);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(44, 57, 160) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
