<template>
  <main>
    <router-view v-if="retrieved" />
  </main>
  <ui-dialog />
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const retrieved = ref(false);

store.dispatch("retrieveWorkflowState");
store
  .dispatch("retrieve", ["workflows", "logs"])
  .then(() => {
    retrieved.value = true;
  })
  .catch(() => {
    retrieved.value = true;
  });
</script>
