<template>
  <div
    style="
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 8px;
      max-width: 360px;
    "
  >
    <n-button style="margin: 4px" color="rgb(44, 57, 160)" hidden>
      <span style="font-weight: 600; margin-right: 8px">Placeholder</span>
      <n-icon>
        <add-icon style="font-size: 16px" />
      </n-icon>
    </n-button>
    <div v-for="column in columns">
      <n-button
        @click="handleClick(column)"
        style="margin: 4px"
        color="rgb(44, 57, 160)"
      >
        <span style="font-weight: 600; margin-right: 8px">{{ column }}</span>
        <n-icon>
          <add-icon style="font-size: 16px" />
        </n-icon>
      </n-button>
    </div>
  </div>
</template>
<script>
import { getCampaignDetail } from "../../api";
import { AddOutline as AddIcon } from "@vicons/ionicons5";
import { NButton, NIcon } from "naive-ui";
import emitter from "tiny-emitter/instance";

export default {
  props: {
    editor: {
      type: Object,
    },
    block: {
      type: Object,
    },
  },
  components: { NButton, NIcon, AddIcon },
  data() {
    return {
      loading: false,
      columns: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    handleClick(column) {
      const block = this.block;
      const editor = this.editor;
      let value = block.data.text;
      value = value + ` $${column}$ `;

      editor.updateNodeDataFromId(block.id, {
        ...block.data,
        text: value,
      });
      block.data.text = value;
      emitter.emit("editor:data-changed", block.id);
    },
    fetch() {
      this.loading = true;
      getCampaignDetail().then((response) => {
        this.loading = false;
        this.columns = response.data?.customer_fields;
      });
    },
  },
};
</script>
